import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"max-width":"1000px"},model:{value:(_vm.viewMessageDialog),callback:function ($$v) {_vm.viewMessageDialog=$$v},expression:"viewMessageDialog"}},[_c(VCard,[_c(VIcon,{attrs:{"large":""},on:{"click":function($event){_vm.viewMessageDialog = !_vm.viewMessageDialog}}},[_vm._v("mdi-close-circle-outline")]),_c('h2',{staticClass:"text-center"},[_vm._v("View Message")]),_c('div',[_c(VCard,{staticClass:"messagetile",attrs:{"color":"#16167A","dark":""}},[_c(VCardTitle,{staticClass:"headline"},[_vm._v("To "+_vm._s(_vm.message.CamperFirstName + ' ' + _vm.message.CamperLastName))]),_c(VCardSubtitle,[_vm._v("Sent On "+_vm._s(_vm.message.MessageSentOn))]),_c('p',{staticClass:"message"},[_vm._v("Message: "+_vm._s(_vm.message.Message))])],1),_c('br'),(_vm.message.ReplySent == 1)?_c('div',[_c(VCard,{staticClass:"replytitle",attrs:{"color":"#157034","dark":""}},[_c(VCardTitle,{staticClass:"headline"},[_vm._v("From "+_vm._s(_vm.message.CamperFirstName + ' ' + _vm.message.CamperLastName))]),_c(VCardSubtitle,[_vm._v("Received On "+_vm._s(_vm.message.ReplyUpdatedAt))]),_c('p',{staticClass:"message"},[_vm._v("Message: "+_vm._s(_vm.message.ReplyMessage))])],1)],1):_vm._e(),_c('div',{staticClass:"text-center"},[_c(VBtn,{attrs:{"rounded":"","color":"primary"},on:{"click":function($event){_vm.viewMessageDialog = !_vm.viewMessageDialog}}},[_vm._v("Close")])],1)],1),_c('br'),_c('br')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }