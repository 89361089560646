<template>
  <div >
    <v-dialog v-model="viewMessageDialog" max-width="1000px">
      <v-card>
        <v-icon large @click="viewMessageDialog = !viewMessageDialog">mdi-close-circle-outline</v-icon>
        <h2 class="text-center">View Message</h2>
        <div>
          <v-card color="#16167A" dark class="messagetile">
            <v-card-title
              class="headline"
            >To {{message.CamperFirstName + ' ' + message.CamperLastName}}</v-card-title>
            <v-card-subtitle>Sent On {{message.MessageSentOn}}</v-card-subtitle>
            <p class="message">Message: {{message.Message}}</p>
          </v-card>
          <br />
          <div v-if="message.ReplySent == 1">
            <v-card color="#157034" dark class="replytitle">
              <v-card-title
                class="headline"
              >From {{message.CamperFirstName + ' ' + message.CamperLastName}}</v-card-title>
              <v-card-subtitle>Received On {{message.ReplyUpdatedAt}}</v-card-subtitle>
              <p class="message">Message: {{message.ReplyMessage}}</p>
            </v-card>
          </div>
          <div class="text-center">
            <v-btn rounded color="primary" @click="viewMessageDialog = !viewMessageDialog">Close</v-btn>
          </div>
        </div>
        <br />
        <br />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "ViewMessage",
  components: {},
  props: [],
  data() {
    return {
      viewMessageDialog: false,
      message: []
    };
  },
  async mounted() {},
  methods: {
    openMessage(message) {
      [this.message] = message;
      this.viewMessageDialog = true;
    }
  }
};
</script>
<style scoped>
.messagetile {
  margin-right: 10%;
  margin-left: 2%;
}
.replytitle {
  margin-right: 2%;
  margin-left: 10%;
}
.message {
  margin-left: 3%;
}
</style>