import Api from './Api'

export default {

    newMessage(message) {
        return Api().post('messages/add', message)
    },
    verifyReplyToken(token) {
        return Api().get(`messages/reply/verifytoken/?ReplyToken=${token}`)
    },
    newReply(reply) {
        return Api().post('messages/reply/send', reply)
        
    }, repliedMessages() {
        return Api().get('messages/reply')
    },
    allMessage() {
        return Api().get('messages')
    },
}
