import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c('PageHeader'),_c('ViewMessage',{ref:"viewMessage"}),_c(VContent,[(_vm.loading)?_c('loading'):_vm._e(),(!_vm.loading)?_c(VContainer,[(!_vm.messages.length)?_c('div',{staticClass:"text-center"},[_c('h1',[_vm._v("No Messages")]),_c('br'),_c(VBtn,{attrs:{"large":"","rounded":"","text":""},on:{"click":_vm.sendMessage}},[_vm._v("Click Here To Send New Message")])],1):_vm._e(),_c(VRow,{attrs:{"dense":""}},_vm._l((_vm.messages),function(message){return _c(VCol,{key:message.MessageId,attrs:{"cols":"12"}},[(message.ReplySent == 1)?_c('div',[_c(VCard,{attrs:{"color":"#467790","dark":""}},[_c(VCardTitle,{staticClass:"headline"},[_vm._v("From "+_vm._s(message.CamperFirstName + ' ' + message.CamperLastName))]),_c(VCardSubtitle,[_vm._v("Received On "+_vm._s(message.ReplyUpdatedAt))]),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.seeMessage(message.MessageId)}}},[_vm._v("Click Here To See Message")])],1)],1)],1):_vm._e(),(message.ReplySent == 0)?_c('div',[_c(VCard,{attrs:{"color":"#385F73","dark":""}},[_c('div',[_c('div',[_c(VCardTitle,{staticClass:"headline"},[_vm._v("To "+_vm._s(message.CamperFirstName + ' ' + message.CamperLastName))]),_c(VCardSubtitle,[_vm._v("Sent On "+_vm._s(' ' + message.MessageSentOn))])],1),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.seeMessage(message.MessageId)}}},[_vm._v("Click Here To See Message")])],1)],1)])],1):_vm._e()])}),1)],1):_vm._e()],1),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }