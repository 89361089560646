<template>
  <v-app>
    <PageHeader />
    <ViewMessage ref="viewMessage" />
    <v-content>
      <loading v-if="loading" />
      <v-container v-if="!loading">
        <div class="text-center" v-if="!messages.length">
          <h1>No Messages</h1>
          <br />
          <v-btn large rounded text @click="sendMessage">Click Here To Send New Message</v-btn>
        </div>
        <v-row dense>
          <v-col cols="12" v-for="message in messages" :key="message.MessageId">
            <div v-if="message.ReplySent == 1">
              <v-card color="#467790" dark>
                <v-card-title
                  class="headline"
                >From {{message.CamperFirstName + ' ' + message.CamperLastName}}</v-card-title>
                <v-card-subtitle>Received On {{message.ReplyUpdatedAt}}</v-card-subtitle>
                <v-card-actions>
                  <v-btn text @click="seeMessage(message.MessageId)">Click Here To See Message</v-btn>
                </v-card-actions>
              </v-card>
            </div>
            <div v-if="message.ReplySent == 0">
              <v-card color="#385F73" dark>
                <div>
                  <div>
                    <v-card-title
                      class="headline"
                    >To {{message.CamperFirstName + ' ' + message.CamperLastName}}</v-card-title>
                    <v-card-subtitle>Sent On {{' ' + message.MessageSentOn}}</v-card-subtitle>
                  </div>
                  <v-card-actions>
                    <v-btn @click="seeMessage(message.MessageId)" text>Click Here To See Message</v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
    <Footer />
  </v-app>
</template>
<script>
import messagesService from "../services/Messages";
import PageHeader from "@/components/Header";
import Footer from "@/components/Footer";
import ViewMessage from "../components/ViewMessage";
import loading from "../components/Loading";
import moment from "moment";
export default {
  name: "ViewMessages",
  components: { PageHeader, Footer, ViewMessage, loading },
  data() {
    return {
      messages: [],
      loading: true
    };
  },
  async mounted() {
    this.messages = (await messagesService.allMessage()).data;

    this.messages.forEach(message => {
      message.MessageSentOn = moment(message.MessageSentOn).format("LLL");
      message.ReplyUpdatedAt = moment(message.ReplyCreatedAt).format("LLL");
    });
    this.loading = false;
  },
  methods: {
    async seeMessage(selectedMessagesId) {
      var selectedMessage = this.messages.filter(obj => {
        return obj.MessageId === selectedMessagesId;
      });
      // selectedMessage[0].Message.replace(/↵/g, "<br/>");
      // /\u21B5/g, "<br/>";
      this.$refs.viewMessage.openMessage(selectedMessage);
    },
    sendMessage() {
      this.$router.push({ name: "SendMessage" });
    }
  }
};
</script>
<style scoped>
.seemessage {
  padding-right: 50%;
}
</style>